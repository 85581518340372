import styled from 'styled-components';

export const Container = styled.div`
  .row {
    padding: 0 !important;
    margin: 0;
  }
  @media (max-width: 480px) {
    .content-item {
      padding: 0;
    }
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    .content-item {
      padding-bottom: 30px;
    }
  }
`;
