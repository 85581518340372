import styled from 'styled-components';

export const Load = styled.div`
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  align-items: top;
  justify-content: left;

`;
