import React from 'react';
import PropTypes from 'prop-types';
import Item from './item/item';
import { Row, Col } from 'reactstrap';
import { Container } from './gallery.css';

const Gallery = ({ items, showMore, showLess }) => (
  <Container>
    <Row>
      {items.map((item, index) => (
        <Col
          className={'content-item'}
          lg={3}
          md={item.homepage === true ? 6 : 3}
          sm={item.homepage === true ? 6 : 6}
          key={index}
        >
          <Item {...item} showMore={showMore} showLess={showLess} />
        </Col>
      ))}
    </Row>
  </Container>
);

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  showMore: PropTypes.string,
  showLess: PropTypes.string,
};

export default Gallery;
