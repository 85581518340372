import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { WistiaStyle } from './wistia.css';
import { trackEvent } from '../../helpers/tagManager';

const WistiaVideo = ({ code, title, pageLoading }) => {
  const imgRef = useRef();

  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.src = `https://fast.wistia.com/embed/medias/${code}.jsonp`;
    script1.async = true;

    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    const handleWistiaReady = () => {
      window._wq = window._wq || [];
      // eslint-disable-next-line no-undef
      _wq.push({
        id: code,
        onReady: (video) => {
          const trackVideo = (action) => {
            trackEvent({
              category: 'VideoInteraction',
              action,
              lavel: title,
            });
          };

          video.bind('play', () => trackVideo('started'));
          video.bind('end', () => trackVideo('ended'));
          video.bind('secondchange', (s) => {
            const percentage = (s / video.duration()) * 100;
            if (!video.tracked25 && percentage >= 25) {
              video.tracked25 = true;
              trackVideo('reached25%');
            }
            if (!video.tracked50 && percentage >= 50) {
              video.tracked50 = true;
              trackVideo('reached50%');
            }
            if (!video.tracked75 && percentage >= 75) {
              video.tracked75 = true;
              trackVideo('reached75%');
            }
          });
        },
      });
    };

    script2.onload = handleWistiaReady;

    document.body.appendChild(script1);
    document.body.appendChild(script2);
  }, []);

  /* eslint-disable */
  return (
    <WistiaStyle>
      <div
        className={'wistia_responsive_padding'}
        style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          }}
        >
          <div
            className={
              'wistia_embed wistia_async_' + code + ' seo=false videoFoam=true'
            }
            title={title}
            style={{ height: '100%', position: 'relative', width: '100%' }}
          >
            <div
              className="wistia_swatch"
              style={{
                height: '100%',
                left: 0,
                top: 0,
                position: 'absolute',
                opacity: 0,
                overflow: 'hidden',
                transition: '0ms',
                width: '100%',
              }}
            >
              <img
                src={'https://fast.wistia.com/embed/medias/' + code + '/swatch'}
                ref={imgRef}
                style={{
                  filter: 'blur(5px)',
                  height: '100%',
                  objectFit: 'contain',
                  width: 'contain',
                }}
                alt=""
                onLoad={() => {
                  imgRef.current.parentNode.style.opacity = 1;
                  if (
                    typeof pageLoading !== 'undefined' &&
                    window.Wistia !== 'undefined'
                  ) {
                    pageLoading();
                  }
                }}
                title={title}
                allowtransparency="true"
              />
            </div>
          </div>
        </div>
      </div>
    </WistiaStyle>
  );
  /* eslint-enable */
};

WistiaVideo.propTypes = {
  code: PropTypes.string.isRequired,
  title: PropTypes.string,
  pageLoading: PropTypes.func,
};

export default WistiaVideo;
