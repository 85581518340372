import { Field, Form, Formik } from 'formik';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavLink,
  Row,
} from 'reactstrap';
import Modal from '../../components/modal/modal';
import ConditionsContainer from '../../components/search/modal-conditions';
import { getGeographicalCoordinates } from '../../functions/googleMap';
import { registerTrackEvent } from '../../helpers/tagManager';
//style
import { Fonts, SimpleSearch } from '../../global.css';

const FindATrial = ({
  data,
  location,
  navigate,
  pageContext,
  searchCallBack,
  isLight,
  isAdv,
  pageSearch,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [formInitialized, setFormInitialized] = useState(false);
  const [preselectedCountry, setPreselectedCountry] = useState({
    InternalValue: '',
    DisplayValue: '',
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchCountry, setSearchCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [changed, setChanged] = useState(false);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [searchParams, setSearchParams] = useState(null);
  const [preselectedZip, setPreselectedZip] = useState('');

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.manifest &&
      window.manifest.masterData
    ) {
      data.site.siteMetadata.masterData = window.manifest.masterData;
    }

    let tempSearchParams;
    try {
      tempSearchParams = new URL(location.href).searchParams;
    } catch (e) {
      tempSearchParams = new URLSearchParams('');
    }

    setSearchParams(tempSearchParams);

    if (tempSearchParams.get('Conditions')) {
      setSelectedConditions(tempSearchParams.get('Conditions').split('~'));
    }

    let localPreselectedCountry = '';
    if (tempSearchParams.get('country')) {
      localPreselectedCountry = tempSearchParams.get('country');
    }

    if (tempSearchParams.get('zip')) {
      setPreselectedZip(tempSearchParams.get('zip'));
    }

    if (selectedConditions && selectedConditions.length) {
      let selectedDisplayValues = [];
      if (
        data.site.siteMetadata.masterData.Data &&
        Array.isArray(data.site.siteMetadata.masterData.Data.CONDITION)
      ) {
        data.site.siteMetadata.masterData.Data.CONDITION.forEach(condition => {
          if (selectedConditions.includes(condition.InternalValue)) {
            selectedDisplayValues.push(condition.DisplayValue);
          }
          if (condition.Children && condition.Children.length) {
            condition.Children.forEach(condition => {
              if (selectedConditions.includes(condition.InternalValue)) {
                selectedDisplayValues.push(condition.DisplayValue);
              }
            });
          }
        });
      }
      setSelectedOptions(selectedDisplayValues.join(', '));
      setFormInitialized(true);
      setPreselectedCountry(getCountryIfExist(localPreselectedCountry));
      setCountries(
        data.site.siteMetadata.masterData.Data
          ? data.site.siteMetadata.masterData.Data.COUNTRY
          : []
      );
    } else {
      setFormInitialized(true);
      setPreselectedCountry(getCountryIfExist(localPreselectedCountry));
      setCountries(
        data.site.siteMetadata.masterData.Data
          ? data.site.siteMetadata.masterData.Data.COUNTRY
          : []
      );
    }
  }, []);

  const onKeyDownCountry = event => {
    if (dropdownOpen && event && event.key && /[a-z,A-Z]/.test(event.key)) {
      let localSearchCountry = searchCountry;
      if (event.key.length === 1) {
        localSearchCountry = searchCountry + event.key;
      } else {
        if (event.which && event.which === 8 && localSearchCountry.length) {
          localSearchCountry = localSearchCountry.substring(
            0,
            localSearchCountry.length - 1
          );
        }
      }
      setSearchCountry(localSearchCountry);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    setSearchCountry('');
  };

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const resetForm = () => {
    if (
      typeof window !== 'undefined' &&
      typeof window.location !== 'undefined'
    ) {
      window.location.reload();
    } else {
      if (location && location.reload) {
        location.reload();
      }
    }
  };

  const handleSubmit = values => {
    let tempSearchParams = null;
    try {
      tempSearchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      tempSearchParams = new URLSearchParams('');
    }

    setSearchParams(tempSearchParams);

    let finalQuery = {};
    tempSearchParams.forEach((value, key) => {
      finalQuery[key] = value;
    });
    Object.keys(values).forEach(key => {
      finalQuery[key] = values[key];
    });
    navigate(
      `/${pageContext.langKey}/results/?${Object.keys(finalQuery)
        .map(
          k =>
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent(
              Array.isArray(finalQuery[k])
                ? finalQuery[k].join('~')
                : `${finalQuery[k]}`
            )
        )
        .join('&')}`
    );
    if (searchCallBack && typeof searchCallBack === 'function') {
      setTimeout(() => {
        searchCallBack();
      });
    }
  };

  const getCountryIfExist = country => {
    let localCountry = data.site.siteMetadata.masterData.Data.COUNTRY.find(
      item => item.InternalValue === country
    );
    if (!localCountry) {
      return { InternalValue: '', DisplayValue: '' };
    }
    return {
      InternalValue: localCountry.InternalValue,
      DisplayValue: localCountry.DisplayValue,
    };
  };

  return (
    <Fonts>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SimpleSearch>
          {isLight ? '' : isAdv ? '' : <h2 className={'pink'}>Find a Trial</h2>}
          {formInitialized && (
            <Formik
              enableReinitialize={false}
              initialValues={
                isLight
                  ? {
                      SearchTerm:
                        (searchParams && searchParams.get('SearchTerm')) || '',
                      zip: preselectedZip,
                      country: preselectedCountry.InternalValue,
                    }
                  : {
                      Conditions:
                        selectedConditions && selectedConditions.length
                          ? selectedConditions
                          : [],
                      zip: preselectedZip,
                      country: preselectedCountry.InternalValue,
                      SearchTerm:
                        (searchParams && searchParams.get('SearchTerm')) || '',
                      Gender:
                        (searchParams &&
                          searchParams.get('Gender') &&
                          searchParams.get('Gender').split('~')) ||
                        [],
                      AgeRanges:
                        (searchParams &&
                          searchParams.get('AgeRanges') &&
                          searchParams.get('AgeRanges').split('~')) ||
                        [],
                      Status:
                        (searchParams &&
                          searchParams.get('Status') &&
                          searchParams.get('Status').split('~')) ||
                        [],
                    }
              }
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                if (!isLight) {
                  values.Conditions = selectedConditions;
                }
                if (
                  values.country &&
                  values.country.length &&
                  values.zip &&
                  values.zip.length
                ) {
                  const countryName = values.country.replace('COUNTRY_', '');
                  getGeographicalCoordinates(
                    countryName,
                    values.zip && values.zip.length ? values.zip : ''
                  )
                    .then(response => {
                      switch (response.tag) {
                        case 'Success': {
                          const { Longitude, Latitude } = response.value;
                          values.Latitude = Latitude;
                          values.Longitude = Longitude;
                          return true;
                        }
                        case 'Error': {
                          /*eslint quotes: [2, "single", "avoid-escape"]*/
                          alert(
                            typeof response.value === 'string' &&
                              response.value === 'Not found'
                              ? 'Invalid zip code and country combination'
                              : "We can't find specified address"
                          );
                          return false;
                        }
                      }
                    })
                    .then(success => {
                      actions.setSubmitting(false);
                      if (!success) {
                        return false;
                      }
                      handleSubmit(values);
                    });
                } else {
                  values.Latitude = '';
                  values.Longitude = '';
                  handleSubmit(values);
                  actions.setSubmitting(false);
                }
                setTimeout(() => {
                  pageSearch ? pageSearch() : null;
                }, 1000);
              }}
              render={({ isSubmitting, handleChange, setFieldValue }) => (
                <Form onReset={resetForm}>
                  <Row className={'form-row'}>
                    {isLight ? (
                      <Col
                        className={
                          'general-form-input' +
                          (isLight === true ? ' results-keyword-section' : '')
                        }
                        xl={isLight ? 4 : 5}
                        lg={isLight ? 4 : 5}
                        md={12}
                      >
                        {' '}
                        {isLight ? (
                          <label htmlFor={'keyword'}>Keyword Search</label>
                        ) : (
                          ''
                        )}
                        <Field
                          className={isLight ? 'results-input' : ''}
                          type="text"
                          name="SearchTerm"
                          autoComplete="off"
                          id="keyword"
                          maxLength="50"
                          placeholder={data.trialSearchEnJson.keyword}
                        />
                      </Col>
                    ) : (
                      <Col md={12} className={'modal-button'}>
                        <p style={{ fontWeight: '900' }}>
                          {data.trialSearchEnJson.titleMC}
                        </p>
                        <Modal
                          type={'conditions'}
                          open={modalOpen}
                          showModal={showModal}
                          hideModal={hideModal}
                          arrow={true}
                          trackEvent={{
                            label: "Conditions"
                          }}
                          button={
                            typeof selectedOptions === 'string'
                              ? selectedOptions.length
                                ? selectedOptions
                                : data.trialSearchEnJson.allSelected
                              : data.trialSearchEnJson.buttonText
                          }
                        >
                          <ConditionsContainer
                            conditionsList={
                              data.site.siteMetadata.masterData.Data
                                ? data.site.siteMetadata.masterData.Data
                                    .CONDITION
                                : []
                            }
                            healthVol={
                              data.site.siteMetadata.masterData.Data
                                ? data.site.siteMetadata.masterData.Data
                                    .HEALTHY_VOL
                                : []
                            }
                            handleModal={hideModal}
                            title={
                              data.trialSearchEnJson.selectMedicalCondition
                            }
                            all={data.trialSearchEnJson.all}
                            clear={data.trialSearchEnJson.clear}
                            accept={data.trialSearchEnJson.accept}
                            ok={data.trialSearchEnJson.ok}
                            preselectedConditions={selectedConditions}
                            onSelected={(list, names) => {
                              setSelectedConditions(list);
                              setSelectedOptions(names.join(', '));
                            }}
                            allSelected={() => {
                              setSelectedOptions('');
                              setSelectedConditions([]);
                            }}
                          />
                        </Modal>
                      </Col>
                    )}
                    <Col
                      className={
                        'select-country' +
                        (isLight ? ' country-col-results' : '')
                      }
                      xl={isLight ? 3 : 6}
                      lg={isLight ? 4 : 6}
                      md={isLight ? 6 : 6}
                      sm={12}
                    >
                      <p className={isLight ? 'textResults' : ''}>
                        {data.trialSearchEnJson.titleCountry}
                      </p>
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        className={'select-dropdown'}
                      >
                        <DropdownToggle
                          className={
                            'select-button' + (isLight ? ' results-btn' : '')
                          }
                          tag="button"
                          type="button"
                          name="country"
                          onKeyDown={e => onKeyDownCountry(e)}
                          onChange={ev => {
                            handleChange(ev);
                            if (ev.target.value.length === 0)
                              setFieldValue('zip', '');
                          }}
                        >
                          {searchCountry && searchCountry.length
                            ? searchCountry
                            : preselectedCountry.DisplayValue.length
                            ? preselectedCountry.DisplayValue
                            : isLight
                            ? 'All Countries'
                            : changed === false
                            ? 'Select Country'
                            : 'All Countries'}{' '}
                          <IoIosArrowDown />
                        </DropdownToggle>
                        <DropdownMenu
                          className={isLight ? ' results-dropdown-menu' : ''}
                          flip={false}
                          direction="down"
                          style={{
                            maxHeight: '300px',
                            overflow: 'auto',
                          }}
                        >
                          {[
                            {
                              InternalValue: '',
                              DisplayValue: 'All Countries',
                            },
                          ]
                            .concat(
                              countries
                                .filter(country => {
                                  return searchCountry === ''
                                    ? true
                                    : country.DisplayValue.toLocaleLowerCase().indexOf(
                                        searchCountry.toLocaleLowerCase()
                                      ) >= 0;
                                })
                                .sort((a, b) => a.SortOrder - b.SortOrder)
                            )
                            .map(country => (
                              <DropdownItem
                                onClick={ev => {
                                  setPreselectedCountry({
                                    InternalValue: country.InternalValue,
                                    DisplayValue: country.DisplayValue,
                                  });
                                  setChanged(true);
                                  handleChange(ev);
                                  setFieldValue(
                                    'country',
                                    country.InternalValue
                                  );
                                  setFieldValue('zip', '');
                                }}
                                key={country.InternalValue}
                                value={country.InternalValue}
                              >
                                {country.DisplayValue}
                              </DropdownItem>
                            ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col
                      className={
                        'general-form-input' + (isLight ? ' resultsPostal' : '')
                      }
                      xl={isLight ? 3 : 6}
                      lg={isLight ? 2 : 6}
                      md={isLight ? 4 : 6}
                      sm={6}
                    >
                      <label
                        htmlFor={'postalCode'}
                        className={isLight ? 'textResults' : ''}
                      >
                        {data.trialSearchEnJson.titlePC}
                      </label>
                      <Field
                        className={isLight ? 'results-input' : ''}
                        type="text"
                        name="zip"
                        maxLength="7"
                        id="postalCode"
                        autoComplete="off"
                        disabled={preselectedCountry.InternalValue === ''}
                        placeholder={
                          isLight
                            ? 'Postal Code'
                            : data.trialSearchEnJson.postalCode
                        }
                      />
                    </Col>
                    <Col
                      lg={isLight ? 2 : ''}
                      md={isLight ? 2 : ''}
                      className={
                        'search-col' +
                        (isLight === true ? ' results-search-btn' : '')
                      }
                    >
                      <button
                        className={
                          'search-button' +
                          (isLight ? ' results-search-btn' : '')
                        }
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {data.trialSearchEnJson.search}
                      </button>
                      {!isLight ? (
                        <div>
                          <NavLink
                            className={'more-button'}
                            href={'/en/find-a-trial/'}
                            onClick={
                              registerTrackEvent({
                                category: 'SiteSearch',
                                action: 'Open',
                                label: data.trialSearchEnJson.openDropDown,
                              })
                            }
                          >
                            {data.trialSearchEnJson.openDropDown}
                          </NavLink>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            />
          )}
        </SimpleSearch>
      </div>
    </Fonts>
  );
};

FindATrial.propTypes = {
  data: PropTypes.object.isRequired,
  navigate: PropTypes.func,
  pageContext: PropTypes.object,
  location: PropTypes.object,
  isLight: PropTypes.bool,
  isAdv: PropTypes.bool,
  searchCallBack: PropTypes.func,
  pageSearch: PropTypes.func,
};

FindATrial.defaultProps = {
  isLight: false,
  isAdv: false,
};

const FindATrialQuery = props => (
  <StaticQuery
    query={graphql`
      query FindATrialQuery {
        trialSearchEnJson {
          medicalCondition
          selectCountry
          openDropDown
          postalCode
          postalCodeResults
          search
          keyword
          keywordPH
          gender
          age
          trialStatus
          selectMedicalCondition
          conditions
          all
          clear
          accept
          yes
          no
          ok
          buttonText
          allSelected
          titleMC
          titleCountry
          titlePC
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        site {
          siteMetadata {
            masterData {
              Data {
                RECSTATUS {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                CONDITION {
                  InternalValue
                  DisplayValue
                  SortOrder
                  Children {
                    InternalValue
                    DisplayValue
                    SortOrder
                  }
                }
                COUNTRY {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                HEALTHY_VOL {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                AGERANGE {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
                GENDER {
                  InternalValue
                  DisplayValue
                  SortOrder
                }
              }
            }
          }
        }
      }
    `}
    render={data => <FindATrial data={data} {...props} />}
  />
);

FindATrialQuery.propTypes = {
  children: PropTypes.node,
};

export default FindATrialQuery;
