import styled from 'styled-components';
import { headerPurple, black, white } from '../../../constants/theme';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
`;

export const ItemStyle = styled.div`
  a {
    color: ${headerPurple};
    font-size: 18px;
    line-height: 26px;
    font-weight: 900;
    white-space: pre-line;
    &:hover {
      color: #382366;
    }
  }
  .gatsby-image-wrapper {
    margin-bottom: 20px;
  }
  button {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 15px;
    color: ${black};
    background-color: ${white};
    border: none;
    border-bottom: 2px solid #eb3c96;
    padding: 0 1px;
    &:focus {
      outline: none;
    }
    &:hover {
      border-bottom: 2px solid #A52A69;
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
  .short {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 110px;
  }
  .long {
    display: flex;
    height: auto;
  }
`;

export const Copy = styled.p`
  color: ${black};
  margin: 20px 0;
  font-size: 16px !important;
  line-height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
