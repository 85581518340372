import React from 'react';
import { Load } from './loading.css';

const Loading = () => {
  return (
    <Load>
      <div className={'loading'}>
        <span>Loading...</span>
      </div>
    </Load>
  );
};

export default Loading;
