import styled from 'styled-components';

export const DisclaimerContent = styled.div`
  padding: 10px 10px 20px 10px;
  h1 {
    color: #503291;
    font-size: 22px;
    font-weight: bold;
    line-height: 32px;
  }
  p {
    color: #1b1b25;
    font-size: 16px;
    font-family: Lato;
    line-height: 27px;
    margin-top: 16px;
  }
  svg {
    color: #eb3c96 !important;
    height: 30px;
    width: 30px;
    &:hover {
      color: #A52A69 !important;
    }
  }
  .close-icon {
    width: 100%;
    display: inline-block;
  }
  .content-modal {
    padding: 0 30px;
  }
`;
