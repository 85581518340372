import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { DisclaimerContent } from './disclaimer.css';
import { FaTimesCircle } from 'react-icons/fa';

const Disclaimer = ({ data, hideModal }) => {
  return (
    <DisclaimerContent>
      <div className={'close-icon'}>
        <FaTimesCircle
          onClick={() => hideModal()}
          style={{
            fontSize: '30px',
            float: 'right',
            cursor: 'pointer',
            color: '#757575',
          }}
        />
      </div>
      <div className={'content-modal'}>
        <div
          dangerouslySetInnerHTML={{
            __html: data.footerJson.disclaimer.childMarkdownRemark.html,
          }}
        />
      </div>
    </DisclaimerContent>
  );
};

Disclaimer.propTypes = {
  data: PropTypes.object.isRequired,
  hideModal: PropTypes.func,
};

const DisclaimerQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        footerJson {
          disclaimer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => <Disclaimer data={data} {...props} />}
  />
);

export default DisclaimerQuery;
