import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Copy, ItemStyle } from './item.css';
import Modal from '../../modal/modal';
import Redirect from '../../redirect/redirect';
import { Link } from 'gatsby';
import { registerTrackEvent } from '../../../helpers/tagManager';

class Item extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showExternalLinkModal: '',
      modalOpen: false,
    };
    this.hideModal = this.hideModal.bind(this);
  }
  collapseText = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  showModal() {
    this.setState({
      modalOpen: true,
    });
  }

  hideModal() {
    this.setState({
      modalOpen: false,
    });
  }

  handleClick(event, url) {
    if (event && (url.indexOf('html') > 0 || url.indexOf('msearch')) > 0) {
      event.preventDefault();
      event.stopPropagation();
      let href = url;
      if (this.state.showExternalLinkModal.length) {
        this.setState({
          showExternalLinkModal: '',
        });
      }
      setTimeout(() => {
        this.setState({
          showExternalLinkModal: href,
          modalOpen: true,
        });
      }, 100);
    }
  }
  render() {
    let {
      image,
      altTitle = null,
      title,
      url,
      copy,
      showMore,
      showLess,
    } = this.props;
    return (
      <ItemStyle>
        <figure>
          <Link to={url} onClick={event => this.handleClick(event, url)}>
            <Img
              fluid={image ? image.childImageSharp.fluid : {}}
              alt={altTitle || title}
              durationFadeIn={0}
              loading="eager"
              onClick={
                registerTrackEvent({
                  category: 'Image',
                  action: 'Click',
                  label: title,
                })
              }
            />
          </Link>
          <figcaption>
            <div className={'title-section'}>
              <Link to={url} onClick={event => this.handleClick(event, url)}>
                {title}
              </Link>
            </div>
            {copy ? (
              <Copy className={this.state.show === true ? 'long' : 'short'}>
                {copy}
              </Copy>
            ) : null}
            {showMore ? (
              <button className={'showmore-btn'} onClick={this.collapseText}>
                {this.state.show === false ? showMore : showLess}
              </button>
            ) : null}
          </figcaption>
        </figure>
        {this.state.showExternalLinkModal &&
        this.state.showExternalLinkModal.length ? (
          <Modal
            autoOpen={true}
            open={this.state.modalOpen}
            type={'redirect'}
            trackEvent={{
              label: "Redirect Popup"
            }}
          >
            <Redirect
              hideModal={this.hideModal}
              url={this.state.showExternalLinkModal}
            />
          </Modal>
        ) : (
          undefined
        )}
      </ItemStyle>
    );
  }
}

Item.propTypes = {
  title: PropTypes.string,
  altTitle: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object.isRequired,
  url: PropTypes.string,
  showMore: PropTypes.string,
  showLess: PropTypes.string,
};

export default Item;
