import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Row, Col } from 'reactstrap';
import Layout from '../components/layout/layout';
import Gallery from '../components/gallery/gallery';
import { getCompanyData, replaceCompanyName } from '../constants/config';
import FindATrial from '../components/search/find-a-trial';
import Disclaimer from '../components/footer/disclaimer/disclaimer';
import Modal from '../components/modal/modal';
import Video from '../components/player/wistia';
import Loading from '../components/loading/loading';

import {
  Fonts,
  HeroHome,
  ShadowSec,
  HomeSectionStyle,
  HomeFindTrial,
  PageBody,
} from '../global.css';

const Index = ({ data, navigate, location, pageContext }) => {
  // const [isClient, setIsClient] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [scriptsLoading, setScriptsLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // setIsClient(true);
      setScriptsLoading(false);
      // setTimeout(() => {
      //   this.loading();
      // }, 2000);
    }
  }, []);

  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const loading = () => {
    setPageLoading(false);
  };

  return scriptsLoading ? (
    ''
  ) : (
    <PageBody>
      <div className={!pageLoading ? 'hidden' : ''}>
        <Loading />
      </div>
      <div className={pageLoading ? 'hidden' : ''}>
        <Layout
          meta={{
            ...replaceCompanyName(data.homeJson, 'seo').seo,
            canonical: (location.origin + location.pathname).replace(
              '/en/',
              '/'
            ),
          }}
        >
          <Fonts>
            <HeroHome>
              <Row>
                <Col lg={7} md={6} xs={12}>
                  <h1>{getCompanyData(data.homeJson, 'heroTitle')}</h1>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: replaceCompanyName(
                        data.homeJson.heroText.childMarkdownRemark.html
                      ),
                    }}
                  />
                </Col>
                <Col lg={5} md={6}>
                  <img
                    src={data.homeJson.heroImg.publicURL}
                    alt={data.homeJson.heroImgTitle}
                    onLoad={() => loading()}
                  />
                </Col>
              </Row>
            </HeroHome>
            <HomeFindTrial>
              <Row>
                <Col lg={9} xs={12}>
                  <FindATrial
                    navigate={navigate}
                    location={location}
                    pageContext={pageContext}
                  />
                </Col>
              </Row>
            </HomeFindTrial>
            <HomeSectionStyle>
              <ShadowSec />
              <Row>
                <Col xs={12}>
                  <h2>{data.homeJson.ctTitle}</h2>
                </Col>
                <Col className={'item'}>
                  <Gallery items={data.homeJson.ctContent} />
                </Col>
              </Row>
            </HomeSectionStyle>
            <HomeSectionStyle>
              <ShadowSec className={'video'} />
              <Row className={'participate-section'}>
                <Col lg={3} md={4}>
                  <h2>{data.homeJson.participateTitle}</h2>
                </Col>
                <Col lg={6} md={8} className={'video-section'}>
                  <Video code={'dc209z9i1g'} title={'Lung Cancer - Linnea'} />
                  <p>{data.homeJson.permission}</p>
                </Col>
              </Row>
            </HomeSectionStyle>
            <HomeSectionStyle>
              <ShadowSec className={'transparency-section'} />
              <Row className={'transparency-section'}>
                <Col xs={12}>
                  <h2>
                    {getCompanyData(data.homeJson, 'clinicalTransparencyTitle')}
                  </h2>
                </Col>
                <Col className={'item'} xs={12}>
                  <Gallery
                    items={data.homeJson.transparencyContent
                      .filter((item) => item.homePage)
                      .map((item) => {
                        return {
                          ...item,
                          url: getCompanyData(item, 'url'),
                        };
                      })}
                    showMore={data.homeJson.showMore}
                    showLess={data.homeJson.showLess}
                  />
                </Col>
              </Row>
            </HomeSectionStyle>
            <HomeSectionStyle>
              <ShadowSec className={'disclaimer'} />
              <Row className={'disclaimer'}>
                <Col>
                  <Modal
                    button={'DISCLAIMER'}
                    hideModal={hideModal}
                    showModal={showModal}
                    open={modalOpen}
                    type={'redirect'}
                    trackEvent={{
                      label: 'Disclaimer',
                    }}
                  >
                    <Disclaimer hideModal={hideModal} />
                  </Modal>
                </Col>
              </Row>
            </HomeSectionStyle>
          </Fonts>
        </Layout>
      </div>
    </PageBody>
  );
};

export default Index;

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
  navigate: PropTypes.func,
};

export const query = graphql`
  query HomepageQuery {
    homeJson {
      seo {
        pageTitle
        pageDescription
      }
      title
      heroTitle
      heroTitle_merck
      heroText {
        childMarkdownRemark {
          html
        }
      }
      heroImgTitle
      heroImg {
        publicURL
      }
      ctTitle
      ctContent {
        title
        altTitle
        url
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      participateTitle
      participateImg {
        childImageSharp {
          fluid(maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      permission
      clinicalTransparencyTitle
      clinicalTransparencyTitle_merck
      transparencyContent {
        title
        altTitle
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        copy
        url
        url_merck
        homePage
      }
      showMore
      showLess
    }
  }
`;
